import { NavLink, useLocation } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { getSidebarItems, MenuGroup, MenuItem } from './sidebar-items';
import { useGetLastViewedProjects } from 'api/useProjectsApi';
import { useSwipeable } from 'react-swipeable';
import { useGetLastViewedMediaSequences } from 'api/useMediaSequencesApi';
import { useAuth } from 'auth/AuthProvider';
import { PermissionProtectedComponent } from 'auth/PermissionProtectedComponent';
import { useGetTicketState } from 'api/useServiceTicketApi';

interface SidebarProps {
  isSidebarOpen: boolean;
  setIsSidebarOpen: (open: boolean) => void;
  children: React.ReactNode;
}

export const Sidebar = ({
  isSidebarOpen,
  setIsSidebarOpen,
  children,
}: SidebarProps) => {
  const { data: projects } = useGetLastViewedProjects(5);
  const { data: mediaSequences } = useGetLastViewedMediaSequences(5);
  const { data: ticketState } = useGetTicketState();
  const { user, hasPermissions } = useAuth();

  const onNavigate = () => {
    if (window.innerWidth < 640) {
      setIsSidebarOpen(!isSidebarOpen);
    }
  };

  const expandSidebarSwipe = useSwipeable({
    onSwipedRight: (e) => {
      // Only expand sidebar if swipe starts from the left edge
      if (e.initial[0] > 40) return;
      setIsSidebarOpen(true);
    },
  });

  const closeSidebarSwipe = useSwipeable({
    onSwipedLeft: () => setIsSidebarOpen(false),
  });

  if (!user) return null;

  const sidebarItems = getSidebarItems(
    user.organization,
    hasPermissions,
    projects ?? [],
    mediaSequences ?? [],
    ticketState
  );

  return (
    <div className="flex flex-col h-screen bg-base-200">
      <aside
        className={twMerge(
          'fixed top-0 left-0 z-20 w-full md:w-72 h-screen pt-16 bg-base-200 transform transition-transform duration-300',
          isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
        )}
        {...closeSidebarSwipe}
      >
        <div className="h-full pr-2 pb-4 overflow-y-auto">
          <ul className="menu font-medium">
            {sidebarItems.map((group, i) => (
              <SidebarItemGroup key={i} group={group} onNavigate={onNavigate} />
            ))}
          </ul>
        </div>
      </aside>
      <div
        className={twMerge(
          'mt-18 transition-margin duration-300 h-full rounded-none md:rounded-tl-xl bg-white',
          isSidebarOpen ? 'hidden md:block md:ml-72' : 'ml-0'
        )}
        {...expandSidebarSwipe}
      >
        {/* Inner app content */}
        {children}
      </div>
    </div>
  );
};

const SidebarItemGroup = ({
  group,
  onNavigate,
}: {
  group: MenuGroup;
  onNavigate: () => void;
}) => {
  return (
    <PermissionProtectedComponent permissions={group.permissions}>
      <li>
        <div className="menu-title flex justify-between items-center font-medium">
          {group.title}
          {group.badge ? (
            <span className="badge badge-sm">{group.badge}</span>
          ) : null}
        </div>
        <ul>
          {group.items.map((item, i) => (
            <SidebarItem key={i} item={item} onNavigate={onNavigate} />
          ))}
        </ul>
      </li>
    </PermissionProtectedComponent>
  );
};

const SidebarItem = ({
  item,
  onNavigate,
}: {
  item: MenuItem;
  onNavigate: () => void;
}) => {
  const location = useLocation();

  const isPathActive = (items?: MenuItem[]): boolean => {
    if (!items) return false;
    return items.some((item) => {
      const isActive =
        item.to !== undefined && location.pathname.includes(item.to);
      return (
        isActive || (item.childrenItems && isPathActive(item.childrenItems))
      );
    });
  };

  const hasChildren = item.childrenItems && item.childrenItems.length > 0;
  const shouldBeOpen =
    item.defaultOpen || (hasChildren && isPathActive(item.childrenItems));

  return (
    <PermissionProtectedComponent permissions={item.permissions}>
      <li>
        {hasChildren ? (
          <details open={shouldBeOpen}>
            <summary className="py-1">
              <SidebarItemDisplay item={item} />
            </summary>
            <ul>
              {item.childrenItems?.map((childItem, i) => (
                <SidebarItem key={i} item={childItem} onNavigate={onNavigate} />
              ))}
            </ul>
          </details>
        ) : item.to !== undefined ? (
          <NavLink
            to={item.to}
            className="py-1"
            onClick={onNavigate}
            end={!item.partialPathMatch}
          >
            <SidebarItemDisplay item={item} />
          </NavLink>
        ) : (
          <div className="pointer py-1" onClick={onNavigate}>
            <SidebarItemDisplay item={item} />
          </div>
        )}
      </li>
    </PermissionProtectedComponent>
  );
};

const SidebarItemDisplay = ({ item }: { item: MenuItem }) => {
  return (
    <>
      {item.icon}
      <span className="font-medium">{item.title}</span>
      {item.indicator ? (
        <span
          className={twMerge(
            'bg-info rounded-full w-2 h-2',
            item.indicatorType === 'error' && 'bg-error'
          )}
        />
      ) : null}
    </>
  );
};
