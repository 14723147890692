import { useMutation, useQuery } from '@tanstack/react-query';

import { queryClient } from 'App';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CoreBaseConfiguration } from './BaseConfiguration';
import {
  AccountApi,
  AuthorizationRequest,
  ResetPasswordRequest,
  ResetPasswordUsingKeyRequest,
} from './core';

const AuthAPIKeys = {
  ME: 'me',
  LOG_OUT: 'log_out',
};

const client = new AccountApi(new CoreBaseConfiguration());

export const useLogin = () => {
  return useMutation({
    mutationFn: ({
      authorizationRequest,
    }: {
      authorizationRequest: AuthorizationRequest;
    }) => client.accountAuthenticatePost({ authorizationRequest }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({ queryKey: [AuthAPIKeys.ME] });
      localStorage.setItem('email', e.email);
    },
    onError: () => {
      toast.warn('Login fejlede');
    },
  });
};

export const useGetMe = () => {
  return useQuery({
    queryKey: [AuthAPIKeys.ME],
    queryFn: () => client.accountMeGet(),
    retry: false,
  });
};

export const useLogout = () => {
  return useMutation({
    mutationFn: () => client.accountLogoutPost(),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [AuthAPIKeys.ME] });
    },
    onError: () => {
      toast.warn('Log ud fejlede');
    },
  });
};

export const useJoinOrganization = () => {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: ({ organizationId }: { organizationId: string }) =>
      client.accountJoinOrganizationPut({ organizationId }),
    onSuccess: () => {
      queryClient.invalidateQueries();
      navigate('/');
    },
    onError: () => {
      toast.warn('Kunne ikke skifte organisation');
    },
  });
};

export const useResetPasswordRequest = () => {
  return useMutation({
    mutationFn: ({
      resetPasswordRequest,
    }: {
      resetPasswordRequest: ResetPasswordRequest;
    }) => client.accountForgottenPasswordRequestPost({ resetPasswordRequest }),
    onSuccess: () => {
      toast.success('Du vil modtage en email med instruktioner');
    },
    onError: () => {
      toast.warn('Kunne ikke nulstille adgangskode');
    },
  });
};

export const useGetThemeForResetPage = (resetKey?: string) => {
  return useQuery({
    queryKey: ['themeForSetPage', resetKey],
    queryFn: () =>
      client.accountForgottenPasswordThemeGet({
        resetKey,
      }),
    enabled: !!resetKey,
    retry: false,
  });
};

export const useResetPassword = () => {
  const loginMutation = useLogin();

  return useMutation({
    mutationFn: ({
      resetPasswordUsingKeyRequest,
    }: {
      resetPasswordUsingKeyRequest: ResetPasswordUsingKeyRequest;
      isRegisteringer: boolean;
    }) =>
      client.accountForgottenPasswordResetPost({
        resetPasswordUsingKeyRequest,
      }),
    onSuccess: (response, request) => {
      toast.success(
        !request.isRegisteringer
          ? 'Velkommen til Estate Engine'
          : 'Din adgangskode er blevet nulstillet'
      );

      loginMutation.mutate({
        authorizationRequest: {
          email: response.email,
          password: request.resetPasswordUsingKeyRequest.password,
        },
      });
    },
    onError: () => {
      toast.warn('Kunne ikke ændre adgangskode');
    },
  });
};
